export const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_REPORT_DOWNLOADING_URL = process.env.REACT_APP_REPORT_DOWNLOADING_URL;

export default {
    LOGIN: `${REACT_APP_API_BASE_URL}/login`,
    RESET_PASSWORD: `${REACT_APP_API_BASE_URL}/request-password-change`,
    CREATE_PASSWORD: `${REACT_APP_API_BASE_URL}/request-password-confirm`,
    GET_SCHOOL: `${REACT_APP_API_BASE_URL}/sign-to-program`,
    REGISTER_FOR_PROGRAM_URL: `${REACT_APP_API_BASE_URL}/confirm-signification`,
    REGISTER_COACH: `${REACT_APP_API_BASE_URL}/coach-invite-confirm`,
};

export const GET_DATA_PARTICIPANT_PROFILE = (participantId) => `${REACT_APP_API_BASE_URL}/participant/${participantId}`;
export const GET_DATA_COACH_PROFILE = (coachId) => `${REACT_APP_API_BASE_URL}/coach/${coachId}`;
export const STATIC_DATA_ROUTE = `${REACT_APP_API_BASE_URL}/defined-data`;
export const GET_DATA_CURRENT_USER = () => `${REACT_APP_API_BASE_URL}/me`;
export const GET_PLATFORM_SETTINGS = (id) => `${REACT_APP_API_BASE_URL}/school/${id}/platform-settings`;
export const SEND_DATA_SETTINGS_USER = () => `${REACT_APP_API_BASE_URL}/user/edit-settings`;
export const SEND_NOTIFICATION_STATUS_SETTINGS = () => `${REACT_APP_API_BASE_URL}/user/switch-notifications`;
export const EDIT_SCHOOL_ADMIN = (id) => `${REACT_APP_API_BASE_URL}/school_admin/${id}`;
export const GET_LIST_OF_PARTICIPANTS = () => `${REACT_APP_API_BASE_URL}/participant`;
export const GET_LIST_OF_COACHES = () => `${REACT_APP_API_BASE_URL}/school-admin/coaches`;
export const SCHOOL_ADMIN_PROGRAMS = `${REACT_APP_API_BASE_URL}/school-admin/programs`;
export const PARTICIPANT_PROGRAMS = `${REACT_APP_API_BASE_URL}/participant/programs`;
export const COACH_PROGRAMS = `${REACT_APP_API_BASE_URL}/profile/coach/programs`;
export const SEND_MESSAGES_TO_USER = () => `${REACT_APP_API_BASE_URL}/chat/send-message`;
export const GET_MESSAGES_FROM_CHAT_ROOM = (roomID) => `${REACT_APP_API_BASE_URL}/chat/get-room/${roomID}`;
export const GET_LIST_ROOMS = () => `${REACT_APP_API_BASE_URL}/chat/rooms`;
export const GET_LIST_USERS = () => `${REACT_APP_API_BASE_URL}/chat/users`;
export const SINGLE_PROGRAM = (role, programID) => {
    if (role === 'coach') {
        return `${REACT_APP_API_BASE_URL}/profile/coach/programs/${programID}`
    } else {
        return `${REACT_APP_API_BASE_URL}/participant/programs/${programID}`
    }
};
export const SEARCH_CHAT_ROOM = () => `${REACT_APP_API_BASE_URL}/chat/rooms`;
export const GET_MESSAGES_FOR_CHAT_ROOM = (userID) => `${REACT_APP_API_BASE_URL}/chat/room/get-by-user/${userID}`;
export const CHOOSE_COACH_API = `${REACT_APP_API_BASE_URL}/participant/choose-coach`;
export const REQUEST_NEW_COACH_API = `${REACT_APP_API_BASE_URL}/participant/request-new-coach`;
export const IMPORT_PARTICIPANTS = (id) => `${REACT_APP_API_BASE_URL}/school-admin/programs/${id}/import-participants`;
export const SESSION_ROUTE = `${REACT_APP_API_BASE_URL}/session`;
export const SESSION_FILTERS_ROUTE = `${REACT_APP_API_BASE_URL}/session/booking/filters`;
export const SESSION_TIME_ROUTE = `${REACT_APP_API_BASE_URL}/user/booked-time-slots`;
export const COACH_ABSENCE_SCHEDULE_ROUTE = `${REACT_APP_API_BASE_URL}/user/absence-schedule`;
export const EDIT_SESSION_ROUTE = (id) => `${REACT_APP_API_BASE_URL}/session/${id}`;
export const COMPLETE_SESSION_ROUTE = (id) => `${REACT_APP_API_BASE_URL}/session/${id}/complete`;
export const LATE_CANCEL_SESSION_ROUTE = (sessionId) => `${REACT_APP_API_BASE_URL}/session/${sessionId}/late-cancel`;
export const ADDITIONAL_SESSION_ROUTE = (programId) => `${REACT_APP_API_BASE_URL}/participant/programs/${programId}/additional-session`;
export const GET_SESSION_ACCEPT_ROUTE = (sessionId) => `${REACT_APP_API_BASE_URL}/session/${sessionId}/accept`;
export const GET_SESSION_DECLINE_ROUTE = (sessionId) => `${REACT_APP_API_BASE_URL}/session/${sessionId}/decline`;
export const GET_LIST_OF_CALENDAR_EVENTS = () => `${REACT_APP_API_BASE_URL}/session/calendar/list`;
export const GET_ALL_FILTERS_OF_CALENDAR = () => `${REACT_APP_API_BASE_URL}/session/calendar/filters`;
export const GET_LIST_OF_GOOGLE_CALENDAR_EVENTS = () => `${REACT_APP_API_BASE_URL}/user/google/events`;
export const GET_LIST_OF_GOOGLE_CALENDARS = () => `${REACT_APP_API_BASE_URL}/user/google/calendars`;
export const GET_LIST_OF_GOOGLE_MEMBERS = () => `${REACT_APP_API_BASE_URL}/user/google/members`;
export const SAVE_TODO_TASKS_TO_ACTIVITY = (topicActivity) => `${REACT_APP_API_BASE_URL}/participant/activity/${topicActivity}/save-todo-task`;
export const REMOVE_TASK_FROM_ACTIVITY = (topicActivity, task) => `${REACT_APP_API_BASE_URL}/participant/activity/${topicActivity}/todo-task/${task}`;
export const UPDATE_TASK_ACTIVITY = (task) => `${REACT_APP_API_BASE_URL}/participant/to-do-task/${task}`;
export const SAVE_FILES_FOR_PROGRAM_TOPIC = (topicID) => `${REACT_APP_API_BASE_URL}/participant/program-topic/${topicID}/save-files`;
export const DELETE_FILES_FOR_PROGRAM_TOPIC = (topicId) => `${REACT_APP_API_BASE_URL}/participant/program-topic/${topicId}/delete-files`;
export const SAVE_FILES_FOR_PROGRAM_TOPIC_ACTIVITY = (topicActivityId) => `${REACT_APP_API_BASE_URL}/participant/program-topic-activity/${topicActivityId}/save-files`;
export const DELETE_FILES_FOR_PROGRAM_ACTIVITY = (topicActivityId) => `${REACT_APP_API_BASE_URL}/participant/program-topic-activity/${topicActivityId}/delete-files`;
export const GET_COMPLETE_ACTIVITY_ROUTE = (activityId) => `${REACT_APP_API_BASE_URL}/participant/activity/${activityId}/complete`;
export const GET_SESSIONS_DASHBOARD = (programID) => `${REACT_APP_API_BASE_URL}/dashboard/session/pie-chart/get/${programID}`;
export const GET_ATTENDANCE_RATE_DIAGRAM_DASHBOARD = (programID) => `${REACT_APP_API_BASE_URL}/dashboard/session/attendance-rate/get/${programID}`;
export const GET_GET_AVERAGE_MARK_DASHBOARD = (programID) => `${REACT_APP_API_BASE_URL}/dashboard/session/satisfaction-average-mark/get/${programID}`;
export const GET_SESSIONS_CREDITS_DASHBOARD = (programID) => `${REACT_APP_API_BASE_URL}/dashboard/session/credits/${programID}`;
export const GET_PARTICIPANTS_INFO_DASHBOARD = (programID) => `${REACT_APP_API_BASE_URL}/dashboard/participants-info/${programID}`;
export const GET_SCHOOL_OVERVIEW_GRAPHS = `${REACT_APP_API_BASE_URL}/dashboard/school-overview`;
export const GET_REPORTS_ROUTE = `${REACT_APP_API_BASE_URL}/report`;
export const GET_SINGLE_REPORT_ROUTE = (reportId) => `${REACT_APP_API_BASE_URL}/report/${reportId}`;
export const GET_ENGAGEMENT_REPORTS_ROUTE = `${REACT_APP_API_BASE_URL}/engagement-overview`;
export const GET_BILLING_OVERVIEW_REPORTS_ROUTE = `${REACT_APP_API_BASE_URL}/report/billing/sessions`;
export const GET_COACH_INVOICES_ROUTE = `${REACT_APP_API_BASE_URL}/report/billing/coach-invoices`;
export const GENERATE_COACH_INVOICE_ROUTE = `${REACT_APP_API_BASE_URL}/report/billing/invoice`;
export const GET_QUESTIONNAIRE_DATA = (surveyId) => `${REACT_APP_API_BASE_URL}/survey/${surveyId}/questions`;
export const SEND_QUESTIONNAIRE_DATA = (surveyId) => `${REACT_APP_API_BASE_URL}/survey/${surveyId}`;
export const GET_REPORTS_CSV = `${REACT_APP_API_BASE_URL}/report/export-csv/bulk`;
export const EDIT_TOPIC_DESCRIPTION_ROUTE = (topicId) => `${REACT_APP_API_BASE_URL}/profile/coach/programs/${topicId}/topic`;
export const EDIT_ACTIVITY_DESCRIPTION_ROUTE = (topicId) => `${REACT_APP_API_BASE_URL}/profile/coach/programs/${topicId}/activity`;
export const CATEGORY_DESCRIPTION_ROUTE = (activityId) => `${REACT_APP_API_BASE_URL}/participant/activity/${activityId}/categorized-descriptions`;
export const GET_USER_NOTIFICATIONS = () => `${REACT_APP_API_BASE_URL}/notifications`;
export const GET_UNREAD_NOTIFICATIONS = () => `${REACT_APP_API_BASE_URL}/notifications/unread-count`;
export const SET_READ_ALL_NOTIFICATIONS = () => `${REACT_APP_API_BASE_URL}/notifications/read`;
export const REMOVE_NOTIFICATIONS = () => `${REACT_APP_API_BASE_URL}/notifications/remove`;
export const GET_GOOGLE_AUTH_LINK = `${REACT_APP_API_BASE_URL}/google/redirect`;
export const CANCEL_GOOGLE_AUTH = `${REACT_APP_API_BASE_URL}/google/logout`;
export const GET_GOOGLE_STATUS = `${REACT_APP_API_BASE_URL}/me/google-account`;
export const DECISIONS_URL = `${REACT_APP_API_BASE_URL}/participant/program-decisions`;
export const ACCEPT_SESSION_BY_COACH_URL = (sessionId) => `${REACT_APP_API_BASE_URL}/session/${sessionId}/accept-as-participant`;

export const participantDashboard = {
    PROGRAMS_PROGRESS: `${REACT_APP_API_BASE_URL}/dashboard/participant/program-progress`,
    PROGRAMS_ACTIVITIES: `${REACT_APP_API_BASE_URL}/dashboard/participant/activities`,
    PROGRAMS_TODOS: `${REACT_APP_API_BASE_URL}/dashboard/participant/todos`,
    UPCOMING_SESSIONS: `${REACT_APP_API_BASE_URL}/dashboard/participant/sessions`
}
