export const GET_PARTICIPANT_PROGRAMS = "GET_PARTICIPANT_PROGRAMS";
export const GET_PARTICIPANT_PROGRAMS_SUCCESS = "GET_PARTICIPANT_PROGRAMS_SUCCESS";
export const GET_PARTICIPANT_PROGRAMS_ERROR = "GET_PARTICIPANT_PROGRAMS_ERROR";

export const GET_USER_SINGLE_PROGRAM = "GET_USER_SINGLE_PROGRAM";
export const GET_USER_SINGLE_PROGRAM_SUCCESS = "GET_USER_SINGLE_PROGRAM_SUCCESS";
export const GET_USER_SINGLE_PROGRAM_ERROR = "GET_USER_SINGLE_PROGRAM_ERROR";
export const UPDATE_USER_SINGLE_PROGRAM_SESSION = "UPDATE_USER_SINGLE_PROGRAM_SESSION";
export const CLEAR_USER_SINGLE_PROGRAM = 'CLEAR_USER_SINGLE_PROGRAM';
export const SET_ACTIVE_TOPIC = 'SET_ACTIVE_TOPIC';

export const GET_COACH_PROGRAMS = "GET_COACH_PROGRAMS";
export const GET_COACH_PROGRAMS_SUCCESS = "GET_COACH_PROGRAMS_SUCCESS";
export const GET_COACH_PROGRAMS_EMPTY = "GET_COACH_PROGRAMS_EMPTY";
export const GET_COACH_PROGRAMS_ERROR = "GET_COACH_PROGRAMS_ERROR";

export const CLEAR_PROGRAMS = 'CLEAR_PROGRAMS';

export const GET_SCHOOL_ADMIN_PROGRAMS = "GET_SCHOOL_ADMIN_PROGRAMS";
export const GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS = "GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS";
export const GET_SCHOOL_ADMIN_PROGRAMS_ERROR = "GET_SCHOOL_ADMIN_PROGRAMS_ERROR";

export const ADD_NEW_ACTION_ITEM = "ADD_NEW_ACTION_ITEM";
export const DELETE_ACTION_ITEMS = "DELETE_ACTION_ITEMS";
export const UPDATE_ACTION_ITEM = "UPDATE_ACTION_ITEM";
export const UPDATE_ACTION_ITEM_LIST_PROGRAMM = "UPDATE_ACTION_ITEM_LIST_PROGRAMM";

export const COMPLETE_ACTIVITY = "COMPLETE_ACTIVITY";
export const COMPLETE_ACTIVITY_SUCCESS = "COMPLETE_ACTIVITY_SUCCESS";
export const COMPLETE_ACTIVITY_ERROR = "COMPLETE_ACTIVITY_ERROR";

export const CLEAR_USER_PROGRAM_ERRORS = 'CLEAR_USER_PROGRAM_ERRORS';

export const UPLOAD_ATTACH_FILE_PROGRAM_TOPIC = "UPLOAD_ATTACH_FILE_PROGRAM_TOPIC";

export const UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY = "UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY";
export const UPDATE_UPLOADED_ATTACHE_FILES = "UPDATE_UPLOADED_ATTACHE_FILES";
export const GET_FILES_DOWNLOAD_ERROR = "GET_FILES_DOWNLOAD_ERROR";

export const DELETE_ATTACH_FILE_TOPIC = "DELETE_ATTACH_FILE_TOPIC";
export const DELETE_ATTACH_FILE_TOPIC_SUCCESS = "DELETE_ATTACH_FILE_TOPIC_SUCCESS";
export const DELETE_ATTACH_FILE_TOPIC_ERROR = "DELETE_ATTACH_FILE_TOPIC_ERROR";
export const DELETE_ATTACH_FILE_ACTIVITY = "DELETE_ATTACH_FILE_ACTIVITY";
export const DELETE_ATTACH_FILE_ACTIVITY_SUCCESS = "DELETE_ATTACH_FILE_ACTIVITY_SUCCESS";
export const DELETE_ATTACH_FILE_ACTIVITY_ERROR = "DELETE_ATTACH_FILE_ACTIVITY_ERROR";
export const CLEAR_DELETE_ATTACH_FILE_INFO = "CLEAR_DELETE_ATTACH_FILE_INFO";

export const EDIT_TOPIC_DESCRIPTION = "EDIT_TOPIC_DESCRIPTION";
export const EDIT_TOPIC_DESCRIPTION_ERROR = "EDIT_TOPIC_DESCRIPTION_ERROR";
export const EDIT_TOPIC_DESCRIPTION_SUCCESS = "EDIT_TOPIC_DESCRIPTION_SUCCESS";

export const CREATE_CATEGORY_DESCRIPTION = "CREATE_CATEGORY_DESCRIPTION";
export const CREATE_CATEGORY_DESCRIPTION_ERROR = "CREATE_CATEGORY_DESCRIPTION_ERROR";
export const CREATE_CATEGORY_DESCRIPTION_SUCCESS = "CREATE_CATEGORY_DESCRIPTION_SUCCESS";

export const EDIT_ACTIVITY_DESCRIPTION = "EDIT_ACTIVITY_DESCRIPTION";
export const EDIT_ACTIVITY_DESCRIPTION_ERROR = "EDIT_ACTIVITY_DESCRIPTION_ERROR";
export const EDIT_ACTIVITY_DESCRIPTION_SUCCESS = "EDIT_ACTIVITY_DESCRIPTION_SUCCESS";

export const COMPLETE_SESSION = 'COMPLETE_SESSION';
export const COMPLETE_SESSION_SUCCESS = 'COMPLETE_SESSION_SUCCESS';
export const COMPLETE_SESSION_ERROR = 'COMPLETE_SESSION_ERROR';

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_REQUEST_SUCCESS = 'SESSION_REQUEST_SUCCESS';
export const SESSION_REQUEST_ERROR = 'SESSION_REQUEST_ERROR';
export const CLEAR_SESSION_REQUEST_DATA = 'CLEAR_SESSION_REQUEST_DATA';

export const CONTINUE_PROGRAM = 'CONTINUE_PROGRAM';
export const CONTINUE_PROGRAM_ERROR = 'CONTINUE_PROGRAM_ERROR';
export const CONTINUE_PROGRAM_SUCCESS = 'CONTINUE_PROGRAM_SUCCESS';
