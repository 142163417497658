import { postData } from "redux/sagas/helpers/requestHandlers";
import { GET_COMPLETE_ACTIVITY_ROUTE } from "constants/api";
import { put, call, select } from "redux-saga/effects";
import {
  completeActivityError,
  completeActivitySuccess,
  showNotification,
} from "redux/actions";
import ResponseError from "helpers/ResponseError";
import { getNotificationMessage } from "helpers/notifications";
import { handleSingleProgram } from "../userPrograms/handleSingleProgram";

export function* completeActivityRequest({
  payload: { activityId, authToken },
}) {
  const { singleProgram } = yield select((state) => state.userPrograms);

  const apiRoute = GET_COMPLETE_ACTIVITY_ROUTE(activityId);
  const { data } = yield call(postData(apiRoute, null, authToken));

  if (data?.status === "Success") {
    yield put(completeActivitySuccess(activityId));
    yield handleSingleProgram(singleProgram.id, activityId);
  } else if (data?.status === "Error") {
    const err = new ResponseError(data);

    yield put(completeActivityError(err.getValidationErrorMessages()));

    yield put(
      showNotification(
        {
          title: err.getMessage(),
          text: getNotificationMessage(err.getValidationErrorMessages()),
        },
        true
      )
    );
  }
}
