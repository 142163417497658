import {
  GET_PARTICIPANT_PROGRAMS,
  GET_PARTICIPANT_PROGRAMS_SUCCESS,
  GET_PARTICIPANT_PROGRAMS_ERROR,
  GET_COACH_PROGRAMS,
  GET_COACH_PROGRAMS_SUCCESS,
  GET_COACH_PROGRAMS_EMPTY,
  GET_COACH_PROGRAMS_ERROR,
  GET_USER_SINGLE_PROGRAM_SUCCESS,
  GET_USER_SINGLE_PROGRAM_ERROR,
  CLEAR_PROGRAMS,
  CLEAR_USER_SINGLE_PROGRAM,
  GET_SCHOOL_ADMIN_PROGRAMS,
  GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS,
  GET_SCHOOL_ADMIN_PROGRAMS_ERROR,
  UPDATE_USER_SINGLE_PROGRAM_SESSION,
  SET_ACTIVE_TOPIC,
  UPDATE_ACTION_ITEM_LIST_PROGRAMM,
  COMPLETE_ACTIVITY,
  COMPLETE_ACTIVITY_SUCCESS,
  COMPLETE_ACTIVITY_ERROR,
  CLEAR_USER_PROGRAM_ERRORS,
  UPDATE_UPLOADED_ATTACHE_FILES,
  GET_FILES_DOWNLOAD_ERROR,
  EDIT_TOPIC_DESCRIPTION_SUCCESS,
  EDIT_TOPIC_DESCRIPTION_ERROR,
  EDIT_ACTIVITY_DESCRIPTION_SUCCESS,
  EDIT_ACTIVITY_DESCRIPTION_ERROR,
  EDIT_INDIVIDUAL_SESSION_ERROR,
  COMPLETE_SESSION_SUCCESS,
  COMPLETE_SESSION_ERROR,
  COMPLETE_SESSION,
  CREATE_CATEGORY_DESCRIPTION_SUCCESS,
  LATE_CANCEL_SESSION_INVITATION_SUCCESS,
  DELETE_ATTACH_FILE_ACTIVITY_SUCCESS,
  DELETE_ATTACH_FILE_TOPIC_SUCCESS,
  DELETE_ATTACH_FILE_TOPIC_ERROR,
  DELETE_ATTACH_FILE_ACTIVITY_ERROR,
  DELETE_ATTACH_FILE_TOPIC,
  DELETE_ATTACH_FILE_ACTIVITY,
  CLEAR_DELETE_ATTACH_FILE_INFO,
  SESSION_REQUEST,
  SESSION_REQUEST_SUCCESS,
  SESSION_REQUEST_ERROR,
  CLEAR_SESSION_REQUEST_DATA,
  CONTINUE_PROGRAM,
  CONTINUE_PROGRAM_ERROR,
  CONTINUE_PROGRAM_SUCCESS,
} from "redux/constants";
import {
  updateSingleProgramSession,
  updateActionItemSingleProgram,
  updateAttachedFilesSingleProgram,
  completeSingleProgramActivity,
  updateSingleProgramTopicDescription,
  updateSingleProgramActivityDescription,
  updateSingleProgramCategoryDescription,
  updateAdditionalSession,
} from "redux/reducers/helpers/updateSingleProgram";

const initialState = {
  programsExist: false,
  programsLoading: false,
  programs: [],
  programsTotal: null,
  singleProgram: {},
  error: null,
  downloadFilesError: null,
  deleteFileProgress: false,
  deleteFileSuccess: false,
  deleteFileError: null,
  continueProgramDecisionStatus: false,
  continueProgramDecisionError: null,
  continueProgramDecisionProgress: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTICIPANT_PROGRAMS:
      return {
        ...state,
        programsLoading: true,
      };
    case GET_PARTICIPANT_PROGRAMS_SUCCESS:
      return {
        ...state,
        programsExist: true,
        programs: payload,
        programsLoading: false,
      };
    case GET_PARTICIPANT_PROGRAMS_ERROR:
      return {
        ...state,
        programsExist: false,
        error: payload,
        programs: [],
      };
    case GET_COACH_PROGRAMS:
      return {
        ...state,
        programsLoading: true,
      };
    case GET_COACH_PROGRAMS_SUCCESS:
      return {
        ...state,
        programsExist: true,
        programs: payload,
        programsLoading: false,
      };
    case GET_COACH_PROGRAMS_EMPTY:
      return {
        ...state,
        programsExist: false,
        programs: payload,
        programsLoading: false,
      };
    case GET_COACH_PROGRAMS_ERROR:
      return {
        ...state,
        programsExist: false,
        error: payload,
        programs: [],
        programsLoading: false,
      };
    case GET_USER_SINGLE_PROGRAM_SUCCESS:
      return {
        ...state,
        singleProgram: payload,
        continueProgramDecisionStatus: false,
        continueProgramDecisionProgress: false,
      };
    case GET_USER_SINGLE_PROGRAM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_USER_SINGLE_PROGRAM:
      return {
        ...state,
        singleProgram: {},
      };
    case UPDATE_USER_SINGLE_PROGRAM_SESSION:
    case LATE_CANCEL_SESSION_INVITATION_SUCCESS:
      return {
        ...state,
        singleProgram: updateSingleProgramSession(state.singleProgram, payload),
      };
    case DELETE_ATTACH_FILE_TOPIC:
    case DELETE_ATTACH_FILE_ACTIVITY:
      return {
        ...state,
        deleteFileProgress: true,
        deleteFileSuccess: false,
        deleteFileError: null,
      };
    case DELETE_ATTACH_FILE_ACTIVITY_SUCCESS:
    case DELETE_ATTACH_FILE_TOPIC_SUCCESS:
      return {
        ...state,
        singleProgram: updateAttachedFilesSingleProgram(
          state.singleProgram,
          payload
        ),
        deleteFileProgress: false,
        deleteFileSuccess: true,
        deleteFileError: null,
      };
    case DELETE_ATTACH_FILE_TOPIC_ERROR:
    case DELETE_ATTACH_FILE_ACTIVITY_ERROR:
      return {
        ...state,
        deleteFileProgress: false,
        deleteFileSuccess: false,
        deleteFileError: payload,
      };
    case CLEAR_DELETE_ATTACH_FILE_INFO:
      return {
        ...state,
        deleteFileProgress: false,
        deleteFileSuccess: false,
        deleteFileError: null,
      };
    case SET_ACTIVE_TOPIC:
      return {
        ...state,
        singleProgram: {
          ...state.singleProgram,
          activeTopic: payload,
        },
      };
    case CLEAR_PROGRAMS:
      return {
        ...state,
        programsExist: false,
        programs: [],
      };
    case GET_SCHOOL_ADMIN_PROGRAMS:
      return {
        ...state,
        programsLoading: true,
      };
    case GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS:
      return {
        ...state,
        programsExist: true,
        programs: payload?.programs,
        programsTotal: payload?.total,
        programsLoading: false,
      };
    case GET_SCHOOL_ADMIN_PROGRAMS_ERROR:
      return {
        ...state,
        programsExist: false,
        error: payload,
        programs: [],
      };
    case UPDATE_ACTION_ITEM_LIST_PROGRAMM:
      return {
        ...state,
        singleProgram: updateActionItemSingleProgram(
          state.singleProgram,
          payload
        ),
      };
    case UPDATE_UPLOADED_ATTACHE_FILES:
      return {
        ...state,
        singleProgram: updateAttachedFilesSingleProgram(
          state.singleProgram,
          payload
        ),
      };
    case COMPLETE_ACTIVITY:
      return {
        ...state,
        error: {
          completeActivityError: null,
        },
      };
    case COMPLETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        singleProgram: completeSingleProgramActivity(
          state.singleProgram,
          payload
        ),
        error: {
          completeActivityError: null,
        },
      };
    case COMPLETE_ACTIVITY_ERROR:
      return {
        ...state,
        error: {
          completeActivityError: payload,
        },
      };
    case CREATE_CATEGORY_DESCRIPTION_SUCCESS:
      return {
        ...state,
        singleProgram: updateSingleProgramCategoryDescription(
          state.singleProgram,
          payload
        ),
        error: {
          createCategoryDescription: null,
        },
      };
    case CLEAR_USER_PROGRAM_ERRORS:
      return {
        ...state,
        error: null,
      };
    case GET_FILES_DOWNLOAD_ERROR:
      return {
        ...state,
        downloadFilesError: payload,
      };
    case EDIT_TOPIC_DESCRIPTION_SUCCESS:
      return {
        ...state,
        singleProgram: updateSingleProgramTopicDescription(
          state.singleProgram,
          payload
        ),
        error: {
          editTopicError: null,
        },
      };
    case EDIT_TOPIC_DESCRIPTION_ERROR:
      return {
        ...state,
        error: {
          editTopicError: payload,
        },
      };
    case EDIT_ACTIVITY_DESCRIPTION_SUCCESS:
      return {
        ...state,
        singleProgram: updateSingleProgramActivityDescription(
          state.singleProgram,
          payload
        ),
        error: {
          editActivityError: null,
        },
      };
    case EDIT_ACTIVITY_DESCRIPTION_ERROR:
      return {
        ...state,
        error: {
          editActivityError: payload,
        },
      };
    case EDIT_INDIVIDUAL_SESSION_ERROR:
      return {
        ...state,
        error: {
          editIndividualSession: payload,
        },
      };
    case COMPLETE_SESSION:
      return {
        ...state,
        completeSessionProgress: true,
        error: {
          completeSession: null,
        },
      };
    case COMPLETE_SESSION_SUCCESS:
      return {
        ...state,
        completeSessionProgress: false,
        singleProgram: updateSingleProgramSession(state.singleProgram, payload),
        error: {
          completeSession: null,
        },
      };
    case COMPLETE_SESSION_ERROR:
      return {
        ...state,
        completeSessionProgress: false,
        error: {
          completeSession: payload,
        },
      };
    case SESSION_REQUEST:
      return {
        ...state,
        singleProgram: {
          ...state.singleProgram,
          sessionRequestStatus: { progress: true },
        },
      };
    case SESSION_REQUEST_SUCCESS:
      return {
        ...state,
        programs: updateAdditionalSession(state.programs, payload),
        singleProgram: {
          ...state.singleProgram,
          sessionRequestStatus: { success: true },
        },
      };
    case SESSION_REQUEST_ERROR:
      return {
        ...state,
        singleProgram: {
          ...state.singleProgram,
          sessionRequestStatus: { error: payload },
        },
      };
    case CLEAR_SESSION_REQUEST_DATA:
      return {
        ...state,
        singleProgram: {
          ...state.singleProgram,
          sessionRequestStatus: {},
        },
      };
    case CONTINUE_PROGRAM:
      return {
        ...state,
        continueProgramDecisionProgress: true,
        continueProgramDecisionError: null,
      };
    case CONTINUE_PROGRAM_ERROR:
      return {
        ...state,
        continueProgramDecisionProgress: false,
        continueProgramDecisionError: payload,
      };
    case CONTINUE_PROGRAM_SUCCESS:
      return {
        ...state,
        continueProgramDecisionStatus: true,
        continueProgramDecisionProgress: false,
      };
    default:
      return state;
  }
}
