import React, { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./Tabs.scss";
import ActonList from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/ActionList/ActonList";
import FileUploader from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/FileUploder/FileUploader";
import DownArrowIcon from "components/icons/DownArrowIcon";
import DetailsTab from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DetailsTab/DetailsTab";
import AttachedFile from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/AttachedFile/AttachedFile";
import useClickOutside from "hooks/useClickOutside";
import { checkIfDecision } from "components/pages/Programmes/helpers/helpers";
import DecisionBox from "components/pages/Programmes/SingleProgramme/ProgrammeTopics/DecisionBox/DecisionBox";

const Tabs = (props) => {
  const { todo_tasks, files, type, activityId, categorized_todo_tasks } = props;
  const [activeTab, setActiveTab] = useState("Details");
  const [isMenuOpen, toggleMenu] = useState(false);
  const tabsRef = useRef();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const searchActivityId = urlSearchParams.get("activityId");
  const isDecisionBox = checkIfDecision(type);

  const handleChangeTab = (tab) => {
    toggleMenu(!isMenuOpen);
    setActiveTab(tab);
  };

  const handleToggleMenu = () => toggleMenu(!isMenuOpen);

  useClickOutside(tabsRef, () => {
    if (isMenuOpen) toggleMenu(false);
  });

  useEffect(() => {
    if (searchActivityId) {
      setActiveTab("Action items");
    }
  }, [searchActivityId]);

  return (
    <div className='tabs' ref={tabsRef}>
      {!isDecisionBox && (
        <button
          className={`tabs__mob-toggle ${
            isMenuOpen ? "tabs__mob-toggle--active" : ""
          }`}
          onClick={handleToggleMenu}
        >
          {activeTab}
          <DownArrowIcon color='#101821' className='arrow-icon' />
        </button>
      )}

      {!isDecisionBox && (
        <div className={`tabs__list ${isMenuOpen ? "tabs__list--open" : ""}`}>
          <button
            className={`tabs__btn ${
              activeTab === "Details" ? "tabs__btn--active" : ""
            }`}
            onClick={() => handleChangeTab("Details")}
          >
            Details
          </button>
          {type !== "module" && (
            <button
              className={`tabs__btn ${
                activeTab === "Action items" ? "tabs__btn--active" : ""
              }`}
              onClick={() => handleChangeTab("Action items")}
            >
              Action items
            </button>
          )}
          <button
            className={`tabs__btn ${
              activeTab === "Files" ? "tabs__btn--active" : ""
            }`}
            onClick={() => handleChangeTab("Files")}
          >
            Files
          </button>
        </div>
      )}
      {(() => {
        switch (activeTab) {
          case "Details":
            return <DetailsTab {...props} />;
          case "Action items":
            return (
              <ActonList
                activityId={activityId}
                categorized_todo_tasks={categorized_todo_tasks}
                todo_tasks={todo_tasks}
              />
            );
          case "Files":
            return (
              <>
                {files.length > 0 && (
                  <AttachedFile
                    files={files}
                    type={type}
                    activityId={activityId}
                  />
                )}
                <FileUploader
                  type={type}
                  activityId={activityId}
                  files={files}
                />
              </>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Tabs;
