import {
  put,
  fork,
  takeLatest,
  call,
  select,
  takeEvery,
} from "redux-saga/effects";
import axios from "axios";
import {
  GET_PARTICIPANT_PROGRAMS,
  GET_COACH_PROGRAMS,
  GET_USER_SINGLE_PROGRAM,
  GET_SCHOOL_ADMIN_PROGRAMS,
  ADD_NEW_ACTION_ITEM,
  DELETE_ACTION_ITEMS,
  UPDATE_ACTION_ITEM,
  UPLOAD_ATTACH_FILE_PROGRAM_TOPIC,
  UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY,
  DELETE_ATTACH_FILE_TOPIC,
  DELETE_ATTACH_FILE_ACTIVITY,
  FETCH_SESSION_INFO,
  CONTINUE_PROGRAM,
} from "redux/constants";

import {
  getParticipantProgramsError,
  getParticipantProgramsSuccess,
  getCoachProgramsError,
  getCoachProgramsSuccess,
  getSchoolAdminProgramsSuccess,
  getSchoolAdminProgramsError,
  updateActionItemUserSingleProgram,
  updateAttachedFilesUserSingleProgram,
  getFilesDownloadError,
  showNotification,
  deleteAttachActivityFileSuccess,
  deleteAttachTopicFileSuccess,
  deleteAttachActivityFileError,
  deleteAttachTopicFileError,
  getCoachProgramsEmpty,
  continueProgramDecisionSuccess,
  continueProgramDecisionError,
} from "redux/actions";

import {
  PARTICIPANT_PROGRAMS,
  COACH_PROGRAMS,
  SCHOOL_ADMIN_PROGRAMS,
  UPDATE_TASK_ACTIVITY,
  REMOVE_TASK_FROM_ACTIVITY,
  SAVE_TODO_TASKS_TO_ACTIVITY,
  SAVE_FILES_FOR_PROGRAM_TOPIC,
  SAVE_FILES_FOR_PROGRAM_TOPIC_ACTIVITY,
  DELETE_FILES_FOR_PROGRAM_TOPIC,
  DELETE_FILES_FOR_PROGRAM_ACTIVITY,
  DECISIONS_URL,
} from "constants/api";

import { handleSingleProgram } from "redux/sagas/userPrograms/handleSingleProgram";
import ResponseError from "helpers/ResponseError";
import { generalApiErrorHandler } from "redux/sagas/helpers/generalApiErrorHandler";
import { deleteData, postData } from "redux/sagas/helpers/requestHandlers";
import { getNotificationMessage } from "helpers/notifications";
import { getToken } from "redux/sagas/helpers/helpers";

function* watchGetUserPrograms() {
  yield takeLatest(GET_PARTICIPANT_PROGRAMS, handleParticipantPrograms);
  yield takeLatest(GET_COACH_PROGRAMS, handleCoachPrograms);
  yield takeLatest(GET_SCHOOL_ADMIN_PROGRAMS, handleScholAdminPrograms);
}

function* handleParticipantPrograms({ payload: token }) {
  try {
    const { data } = yield call(() =>
      axios
        .get(PARTICIPANT_PROGRAMS, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((data) => data)
        .catch((error) => error.response)
    );

    if (data?.status === "Success") {
      yield put(getParticipantProgramsSuccess(data.data));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(getParticipantProgramsError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* handleCoachPrograms({ payload: token, params }) {
  const { programsExist } = yield select((state) => state.userPrograms);

  try {
    const { data } = yield call(() =>
      axios
        .get(COACH_PROGRAMS, {
          headers: { Authorization: `Bearer ${token}` },
          params: { ...params },
        })
        .then((data) => data)
        .catch((error) => error.response)
    );

    if (data?.status === "Success") {
      if (data.data.length > 0) {
        yield put(getCoachProgramsSuccess(data.data));
      } else if (programsExist) {
        yield put(getCoachProgramsSuccess(data.data));
      } else {
        yield put(getCoachProgramsEmpty(data.data));
      }
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(getCoachProgramsError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* handleScholAdminPrograms({ params }) {
  const token = yield select((state) => state.auth.authToken);
  const existPrograms = yield select((state) => state.userPrograms.programs);

  try {
    const { data } = yield axios({
      method: "get",
      url: SCHOOL_ADMIN_PROGRAMS,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      accept: "application/json",
      params: { ...params },
    })
      .then((data) => data)
      .catch((error) => error.response);

    if (data?.status === "Success") {
      const newData = {
        ...data.data,
      };

      if (existPrograms.length > 0 && params?.offset > 0) {
        newData.programs = [...existPrograms, ...data.data.programs];
      }

      yield put(getSchoolAdminProgramsSuccess(newData));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(getSchoolAdminProgramsError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchGetUserSingleProgram() {
  yield takeLatest(
    [GET_USER_SINGLE_PROGRAM, FETCH_SESSION_INFO],
    handleGetUserSingleProgram
  );
}

function* handleGetUserSingleProgram({
  payload: { programId, activityId, callbackFunc },
}) {
  try {
    yield handleSingleProgram(programId, activityId, callbackFunc);
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchUpdateToDoTaskItem() {
  yield takeLatest(UPDATE_ACTION_ITEM, handleUpdateToDoTaskItem);
}

function* handleUpdateToDoTaskItem({ payload }) {
  try {
    const token = yield select(getToken);

    const updateToDoTaskItem = yield axios({
      method: "patch",
      url: UPDATE_TASK_ACTIVITY(payload.id),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      accept: "application/json",
      data: {
        description: payload.description,
        done: !payload.is_done,
      },
    }).then((response) => response.data.data);
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchDeleteToDoTaskItem() {
  yield takeEvery(DELETE_ACTION_ITEMS, handleDeleteToDoTaskItem);
}

function* handleDeleteToDoTaskItem({ payload }) {
  try {
    const token = yield select(getToken);

    const deleteToDoTaskItem = yield axios({
      method: "delete",
      url: REMOVE_TASK_FROM_ACTIVITY(payload.topicActivityID, payload.taskID),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      accept: "application/json",
    }).then((response) => response.data.data);

    yield put(updateActionItemUserSingleProgram(deleteToDoTaskItem));
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchAddNewToDoTaskItem() {
  yield takeEvery(ADD_NEW_ACTION_ITEM, handleAddNewToDoTaskItem);
}

function* handleAddNewToDoTaskItem({ payload }) {
  try {
    const token = yield select(getToken);
    const { description, category_id, due_date } = payload;

    const addNewToDoTaskItem = yield axios({
      method: "post",
      url: SAVE_TODO_TASKS_TO_ACTIVITY(payload.activityId),
      headers: {
        Authorization: `Bearer ${token}`,
      },
      accept: "application/json",
      data: { description, category_id, due_date },
    }).then((response) => response.data.data);

    yield put(updateActionItemUserSingleProgram(addNewToDoTaskItem));
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchUploadAttachFileProgramTopic() {
  yield takeLatest(
    UPLOAD_ATTACH_FILE_PROGRAM_TOPIC,
    handleUploadAttachFileProgramTopic
  );
}

function* handleUploadAttachFileProgramTopic({ payload }) {
  try {
    const token = yield select(getToken);
    const apiRoute = SAVE_FILES_FOR_PROGRAM_TOPIC(payload.topic);
    const formData = new FormData();

    formData.append("files[]", payload.importFile);

    const { data } = yield call(
      postData(apiRoute, formData, token, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    );

    if (data?.status === "Success") {
      yield put(updateAttachedFilesUserSingleProgram(data.data));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(getFilesDownloadError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchUploadAttachFileProgramTopicActivity() {
  yield takeLatest(
    UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY,
    handleUploadAttachFileProgramTopicActivity
  );
}

function* handleUploadAttachFileProgramTopicActivity({ payload }) {
  try {
    const token = yield select(getToken);
    const apiRoute = SAVE_FILES_FOR_PROGRAM_TOPIC_ACTIVITY(
      payload.topicActivity
    );
    const formData = new FormData();

    formData.append("files[]", payload.importFile);

    const { data } = yield call(
      postData(apiRoute, formData, token, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    );

    if (data?.status === "Success") {
      yield put(updateAttachedFilesUserSingleProgram(data.data));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(getFilesDownloadError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchDeleteAttachTopicFile() {
  yield takeLatest(DELETE_ATTACH_FILE_TOPIC, handleDeleteAttachTopicFile);
}

function* handleDeleteAttachTopicFile({ payload: { fileId, files } }) {
  try {
    const token = yield select(getToken);
    const apiRoute = DELETE_FILES_FOR_PROGRAM_TOPIC(fileId);

    const { data } = yield call(
      deleteData(apiRoute, token, { clear_files: files })
    );

    if (data?.status === "Success") {
      yield put(deleteAttachTopicFileSuccess(data.data));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(deleteAttachTopicFileError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchDeleteAttachActivityFile() {
  yield takeLatest(DELETE_ATTACH_FILE_ACTIVITY, handleDeleteAttachActivityFile);
}

function* handleDeleteAttachActivityFile({ payload: { fileId, files } }) {
  try {
    const token = yield select(getToken);
    const apiRoute = DELETE_FILES_FOR_PROGRAM_ACTIVITY(fileId);

    const { data } = yield call(
      deleteData(apiRoute, token, { clear_files: files })
    );

    if (data?.status === "Success") {
      yield put(deleteAttachActivityFileSuccess(data.data));
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(
        deleteAttachActivityFileError(err.getValidationErrorMessages())
      );

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

function* watchContinueProgramDecision() {
  yield takeLatest(CONTINUE_PROGRAM, handleContinueProgramDecision);
}

function* handleContinueProgramDecision({
  payload: { programId, programTopicId, decisionBox, callback },
}) {
  try {
    const token = yield select(getToken);

    const { data } = yield call(
      postData(
        DECISIONS_URL,
        {
          participant_program_id: programId,
          participant_program_topic_id: programTopicId,
          decisions_box: decisionBox,
        },
        token
      )
    );

    if (data?.status === "Success") {
      yield put(continueProgramDecisionSuccess());
      yield handleSingleProgram(programId);
      yield callback;
    } else if (data?.status === "Error") {
      const err = new ResponseError(data);

      yield put(continueProgramDecisionError(err.getValidationErrorMessages()));

      console.log(err.getValidationErrorMessages());

      yield put(
        showNotification(
          {
            title: err.getMessage(),
            text: getNotificationMessage(err.getValidationErrorMessages()),
          },
          true
        )
      );
    }
  } catch (error) {
    yield generalApiErrorHandler(error.message, function* () {
      console.log(error.message);

      yield put(
        showNotification(
          {
            title: "Error",
            text: getNotificationMessage(error.message),
          },
          true
        )
      );
    });
  }
}

export default function* userPrograms() {
  yield fork(watchGetUserPrograms);
  yield fork(watchGetUserSingleProgram);
  yield fork(watchUpdateToDoTaskItem);
  yield fork(watchDeleteToDoTaskItem);
  yield fork(watchAddNewToDoTaskItem);
  yield fork(watchUploadAttachFileProgramTopic);
  yield fork(watchUploadAttachFileProgramTopicActivity);
  yield fork(watchDeleteAttachTopicFile);
  yield fork(watchDeleteAttachActivityFile);
  yield fork(watchContinueProgramDecision);
}
