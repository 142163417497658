import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import Button from "components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { continueProgramDecision } from "redux/actions";
import RadioItem from "components/Radio/Radio";

import "./Decisions.scss";

const Decisions = ({
  decisionBox = [],
  setDecisionsData,
  decisionsData,
  programTopicId,
}) => {
  const history = useHistory();
  const { id: programId } =
    useSelector((state) => state.userPrograms.singleProgram) || {};
  const dispatch = useDispatch();
  const { continueProgramDecisionProgress } = useSelector(
    (state) => state.userPrograms
  );

  const decisions = useMemo(() => {
    return decisionBox?.map((items) => items.decision);
  }, [decisionBox]);

  const handleChange = (value) => {
    setDecisionsData((prev) => ({
      ...prev,
      selectedDecision: value,
    }));
  };

  const handleContinue = () => {
    if (!decisionsData.selectedDecision) return;

    if (decisionsData.selectedDecision === "continue") {
      const foundDecisionBox = decisionBox.find(
        (box) => box.decision?.type === "continue"
      );

      if (foundDecisionBox) {
        console.log("dispatch");

        dispatch(
          continueProgramDecision(
            programId,
            programTopicId,
            {
              id: foundDecisionBox.id,
              decision_id: foundDecisionBox.decision.id,
            },
            () => history.push("/programmes")
          )
        );
      }
    } else {
      setDecisionsData((prev) => ({
        ...prev,
        step: decisionsData.selectedDecision,
      }));
    }
  };

  return (
    <>
      <div className='decision__wrapper'>
        {decisions.map((decision, index) => (
          <RadioItem
            key={index}
            value={decision.type}
            text={decision.name}
            selected={decisionsData?.selectedDecision}
            onChange={handleChange}
          />
        ))}
      </div>
      <Button
        loading={continueProgramDecisionProgress}
        className='button button--blue decision'
        onClick={handleContinue}
      >
        Continue
      </Button>
    </>
  );
};

export default Decisions;
